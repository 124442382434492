<template>
  <div class="success">
    <div class="box3">
      <div class="msg">
        <img src="@/assets/images/successImg.png" class="succImgs" alt="" />
        <div class="succText">
          <div class="tit">作品已成功发布！</div>
          <div>若作品被续写您将获得收益</div>
          <span>提示：可免费续写自己开篇创作的作品</span>
        </div>
      </div>
      <div class="kuang">
        <img
          src="@/components/Header/images/btc.png"
          style="width: 40px; height: 40px"
          alt=""
        />
        <div class="nums">+ 10</div>
        <div class="unit">DAIC</div>
        <div style="line-height: 20px">
          <P>赠送给你的写作鼓励</P>
          <P>多写多得，常写常有~</P>
        </div>
      </div>
      <div class="button">
        <div class="button1" @click="toZp">浏览作品</div>
        <div class="button2" @click="toHome">返回首页</div>
        <!-- <el-button
          type="primary"
          style="color: #000; font-weight: 600; margin-right: 40px"
          @click="toZp"
          >浏览作品
        </el-button>
        <el-button style="color: #000; font-weight: 600" @click="toHome"
          >返回首页
        </el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "success",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toZp() {
      this.$router.push({
        path: "/Details",
      });
    },
    toHome() {
      this.$router.push("/home");
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      nft: (state) => state.page.nft,
    }),
  },
};
</script>

<style lang="less" scoped>
.success {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url(../../assets/images/loginbg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  .box3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin-top: 10vh;
    .msg {
      display: flex;
      .succImgs {
        width: 80px;
        height: 80px;
      }
      .succText {
        margin-left: 30px;
        font-weight: 600;
        line-height: 20px;
        .tit {
          font-weight: 600;
          font-size: 18px;
          height: 40px;
        }
        span {
          font-weight: 500;
          color: #828282;
        }
      }
    }

    .kuang {
      display: flex;
      align-items: center;
      height: 120px;
      width: 400px;
      margin: 50px 0 80px;
      border-radius: 12px;
      padding: 0 30px;
      background: #06070c70;
      .nums {
        font-size: 26px;
        font-weight: 600;
        margin: 0 10px;
      }
      .unit {
        font-size: 18px;
        margin-right: 110px;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      .button1 {
        width: 140px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 10px;
        margin-right: 40px;
        color: #01e39b;
        border: 2px solid #01e39b;
        cursor: pointer;
      }
      .button2 {
        width: 140px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 10px;
        color: #F9F9F9;
        border: 2px solid #F9F9F9;
        cursor: pointer;
      }
    }
  }
}
</style>
